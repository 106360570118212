<template>
  <toast-container/>
  <router-view/>
</template>

<script> // REMOVE BEFORE FLIGHT
  // import { useQualificationStore } from '@/store/QualificationStore.js';
  import { useIncomeStore } from './store/IncomeStore';
  import { useInterfaceStore } from './store/UiStore';
  import { onBeforeUnload } from '@/services/BeforeUnloadService.js';
  import ToastContainer from '@/components/ToastContainer.vue';

  export default {
    components: {
      ToastContainer
    },
    setup() {
      const incomeStore = useIncomeStore();
      const uiStore = useInterfaceStore();

      return { incomeStore, uiStore };
    },
    mounted() {
      window.addEventListener("beforeunload", onBeforeUnload);
    },
    beforeUnmount() {
      window.removeEventListener("beforeunload", onBeforeUnload);
    },
    watch: {
      'incomeStore.nettIncome'() {
        if (this.uiStore.incomeRestoreAttempted) {
            this.incomeStore.save_local();
          }
        this.incomeStore.roundIncome();
      },
      'uiStore.sessionUuid': function() {
        if (this.uiStore.sessionUuid && !this.uiStore.incomeRestoreAttempted) {
          console.log('Attempting to load local income data.');
          this.incomeStore.restore_local();
        }
      }
    }
  }
</script>

<style lang="scss">
  * {
    animation-duration: 0.4s;
    animation-fill-mode: both;
  }
</style>