// import { useInterfaceStore } from '@/store/UiStore';
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';

import { isFunction, isInteger } from 'lodash';
import { enabledSectionsComplete } from '@/store/QualificationStore';
import { StripQuery } from '@/helpers/StripQuery';

// guard function to be implemented in router as per-route guard (array syntax)
// eg: beforeEnter: [FlowGuard],
export function FlowGuard(to) {
  let guardResult = FlowService.guard(to);
  if (guardResult) {
    return guardResult;
  }
}

export const FlowService = {
  // list of views in wizard in order
  sequence: [
    {
      name: 'formContact', // view name in Vue router
      icon: 'bi-telephone',
      label: 'Contactgegevens',
      navHide: false,
      // function returning boolean indicating if section is complete
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.contact.$invalid;
      },
    },
    {
      name: 'formAddress',
      icon: 'bi-geo-alt',
      label: 'Adres',
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.address.$invalid;
      },
    },
    {
      name: 'formOwnership',
      label: 'Eigenaar',
      icon: 'bi-person-bounding-box',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.ownership.$invalid;
      },
    },
    {
      name: 'formAdvice',
      label: 'Advies',
      icon: 'bi-clipboard-check',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.advice.$invalid;
      },
    },
    {
      name: 'formContactPreferences',
      label: 'Voorkeuren',
      icon: 'bi-calendar-check',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.contactPreferences.$invalid;
      },
    },
    {
      name: 'formFinancingSnnSubsidy10k',
      label: 'SNN subsidie',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.snnSubsidy10k.$invalid;
      },
    },
    {
      name: 'formFinancingSnnSubsidy4k',
      label: 'SNN subsidie',
      parent: 'formFinancingSnnSubsidy10k',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.snnSubsidy4k.$invalid;
      },
    },
    {
      name: 'formFinancingIncome',
      label: 'Inkomen',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.income.$invalid;
      },
    },
    {
      name: 'formFinancingNip',
      label: 'NIP subsidie',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.nip.$invalid;
      },
      disabled: () => {
        const lead = useLeadStore();
        return !lead.NIP_address;
      }
    },
    // {
    //   name: 'formFinancingMaatregel29',
    //   label: 'Nij Begun',
    //   icon: 'bi-bank',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.financing.nip.$invalid;
    //   },
    // },
    {
      name: 'formFinancingWarmtefonds',
      label: 'Warmtefonds',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.warmtefonds.$invalid;
      },
    },
    {
      name: 'formFinancingStimuleringslening',
      label: 'Stimuleringslening',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.stimuleringslening.$invalid;
      },
    },
    {
      name: 'formFinancingMortgage',
      label: 'Hypotheek',
      icon: 'bi-bank',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.financing.mortgage.$invalid;
      },
    },
    {
      name: 'formPracticalHelp',
      label: 'Klusbus',
      icon: 'bi-truck',
      navHide: false,
      complete() {
        let v$ = useGlobalVuelidate();
        return !v$.value.practicalHelp.$invalid;
      },
      disabled: () => {
        const lead = useLeadStore();
        return !lead.Eligible_Handyman;
      }
    },
    // {
    //   name: 'formProperty',
    //   label: 'Woning',
    //   icon: 'bi-house-door',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.property.$invalid;
    //   },
    // },
    // {
    //   name: 'formInterests',
    //   label: 'Maatregelen',
    //   dividerAfter: true,
    //   icon: 'bi-ui-checks',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.interests.$invalid;
    //   },
    // },
    // {
    //   name: 'formCavityWall',
    //   label: 'Spouwmuur',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.cavity_wall.general.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Cavity Wall'),
    // },
    // {
    //   name: 'formCavityWallPaint',
    //   label: 'Gevelverf',
    //   parent: 'formCavityWall',
    //   navHide: true,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.cavity_wall.paint.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Cavity Wall'),
    // },
    // {
    //   name: 'formCavityWallStucco',
    //   label: 'Gevelstucwerk',
    //   parent: 'formCavityWall',
    //   navHide: true,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.cavity_wall.stucco.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Cavity Wall'),
    // },
    // {
    //   name: 'formRoof',
    //   label: 'Dakisolatie',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.roof.general.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Roof'),
    // },
    // {
    //   name: 'formRoofAttic',
    //   label: 'Zolder',
    //   navHide: true,
    //   parent: 'formRoof',
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.roof.attic.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Roof'),
    // },
    // {
    //   name: 'formRoofFinishing',
    //   label: 'Binnenzijde dak',
    //   navHide: true,
    //   parent: 'formRoof',
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.roof.finishing.$invalid;
    //   },
    //   disabled() {
    //     let lead = useLeadStore();
    //     return lead.interestsArray.includes('Roof') && !lead.skipRoofFinishing
    //       ? false
    //       : true;
    //   },
    // },
    // {
    //   name: 'formFloor',
    //   label: 'Vloerisolatie',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.floor.general.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Floor'),
    // },
    // {
    //   name: 'formFloorDetails',
    //   label: 'Kruipruimte details',
    //   parent: 'formFloor',
    //   navHide: true,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.floor.details.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Floor'),
    // },
    // {
    //   name: 'formGlazing',
    //   label: 'Dubbelglas',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.glazing.general.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Glazing'),
    // },
    // {
    //   name: 'formGlazingFrame',
    //   label: 'Kozijnen',
    //   navHide: true,
    //   parent: 'formGlazing',
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.glazing.frame.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Glazing'),
    // },
    // {
    //   name: 'formGlazingDimensions',
    //   label: 'Afmetingen',
    //   navHide: true,
    //   parent: 'formGlazing',
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.glazing.dimensions.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Glazing'),
    // },
    // {
    //   name: 'formHeatpump',
    //   label: 'Warmtepomp',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.heatpump.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Heatpump'),
    // },
    // {
    //   name: 'formSolar',
    //   label: 'Zonnepanelen',
    //   navHide: false,
    //   complete() {
    //     let v$ = useGlobalVuelidate();
    //     return !v$.value.solar.$invalid;
    //   },
    //   disabled: measureDisabledFunction('Solar'),
    // },
    {
      name: 'endScreen',
      label: 'Voltooid',
      dividerBefore: true,
      icon: 'bi-trophy-fill',
      navHide: false,
      complete() {
        return enabledSectionsComplete.value;
      },
    },
  ],

  /**
   * get key of view in sequence array by route name
   * when name param is an integer, it will be returned as-is
   * @param {string|number} name name of the route / view, integers are returned as-is
   * @returns {number}
   */
  getViewKeyFromName(name) {
    if (!name) {
      return null;
    }
    if (isInteger(name)) {
      return name;
    }
    for (let i = 0; i < this.sequence.length; i++) {
      const view = this.sequence[i];
      if (view.name == name) {
        return i;
      }
    }
    return null;
  },

  /**
   * if disabled property is set on view object, check if disabled
   * when disabled prop is a function, the function will be run
   * @param {number} key key of view in sequence
   * @returns {boolean}
   */
  isDisabled(key) {
    if (key in this.sequence) {
      let view = this.sequence[key];
      if ('disabled' in view) {
        if (typeof view.disabled === 'boolean') {
          return view.disabled;
        } else if (isFunction(view.disabled)) {
          return view.disabled();
        }
      }
      return false;
    }
  },

  /**
   * if complete property is set on view object, check if complete
   * when complete prop is a function, the function will be run
   * @param {number} key key of view in sequence
   * @param {boolean} defaultValue value returned if view has no complete property
   * @returns {boolean}
   */
  isComplete(key, defaultValue = false) {
    if (key in this.sequence) {
      let view = this.sequence[key];
      if ('complete' in view) {
        if (typeof view.complete === 'boolean') {
          return view.complete;
        } else if (isFunction(view.complete)) {
          return view.complete();
        }
      }
      return defaultValue;
    }
  },

  /**
   * get first view in sequence where complete() == false
   * @param {boolean} fallbackToLast return last view if all views are complete
   * @returns {string}
   */
  getFirstIncomplete(fallbackToLast = false) {
    for (let i = 0; i < this.sequence.length; i++) {
      const view = this.sequence[i];
      if (!view.complete() && !this.isDisabled(i)) {
        return view.name;
      }
    }
    return fallbackToLast ? this.sequence[this.getLastEnabledKey()].name : null;
  },

  /**
   * get last view in sequence where complete() == true
   * @param {boolean} fallbackToFirst return first view if no views are complete
   * @returns {string}
   */
  getLastComplete(fallbackToFirst = false) {
    for (let i = this.sequence.length - 1; i > 0; i--) {
      const view = this.sequence[i];
      if (this.isComplete(i) && !this.isDisabled(i)) {
        return view.name;
      }
    }
    return fallbackToFirst
      ? this.sequence[this.getFirstEnabledKey()].name
      : null;
  },

  // function to get last enabled view, by iterating backwards through sequence
  getLastEnabledKey() {
    for (let i = this.sequence.length - 1; i >= 0; i--) {
      if (this.isDisabled(i) == false) {
        return i;
      }
    }
    return null;
  },

  // function to get first enabled view
  getFirstEnabledKey() {
    for (let i = 0; i < this.sequence.length; i++) {
      if (this.isDisabled(i) == false) {
        return i;
      }
    }
  },

  /**
   * checks all items BEFORE the given key in the sequence array and returns the first item where complete() == false
   * or returns null if all prerequisite items are complete
   * @param {Number} key key (in sequence array) of the current item for which to check the prerequisites
   * @returns {Number}
   */
  getPreReqTodo(key) {
    if (key && key in this.sequence) {
      for (let i = 0; i < key; i++) {
        const view = this.sequence[i];
        if (!view.complete() && !this.isDisabled(i)) {
          return i;
        }
      }
    }
    return null;
  },

  /**
   * get route name of next enabled view in sequence
   * @param {String} viewName route name of current view
   * @returns {String} route name of next view
   */
  getNext(viewName) {
    let currentViewKey = this.getViewKeyFromName(viewName);
    // if not last view
    if (!this.isLast(viewName)) {
      // iterate through subsequent views
      for (let i = currentViewKey + 1; i < this.sequence.length; i++) {
        // if not disabled, return name
        if (this.isDisabled(i) == false) {
          return this.sequence[i].name;
        }
      }
    }
    return null;
  },
  /**
   * variant of getNext that immediately executed a $router.push with params
   * @param {object} vm vue component (this)
   */
  pushNext(vm, query = {}) {
    vm.$router.push({
      name: this.getNext(vm.$route.name),
      params: vm.$route.params,
      query: {...StripQuery(vm.$route.query), ...query}
    });
  },

  /**
   * get route name of previous enabled view in sequence
   * @param {String} viewName route name of current view
   * @returns {String} route name of previous view
   */
  getPrev(viewName) {
    let currentViewKey = this.getViewKeyFromName(viewName);
    // if not first view
    if (!this.isFirst(viewName)) {
      // iterate backwards through previous views
      for (let i = currentViewKey - 1; i >= 0; i--) {
        // if not disabled, return name
        if (this.isDisabled(i) == false) {
          return this.sequence[i].name;
        }
      }
    }
    return null;
  },
  /**
   * variant of getPrev that immediately executed a $router.push with params
   * @param {object} vm vue component (this)
   */
  pushPrev(vm) {
    vm.$router.push({
      name: this.getPrev(vm.$route.name),
      params: vm.$route.params,
    });
  },

  /**
   * check if view is last view in sequence by route name
   * @param {string|number} viewNameKey name or key of view to check
   * @returns {boolean}
   */
  isLast(viewNameKey) {
    // get key of current view
    let viewKey = this.getViewKeyFromName(viewNameKey);
    // check if current view is last enabled view
    if (viewKey !== null) {
      return viewKey == this.getLastEnabledKey();
    }
    return false;
  },

  /**
   * check if view is first view in sequence by route name
   * @param {string|number} viewNameKey name or key of view to check
   * @returns {boolean}
   */
  isFirst(viewNameKey) {
    // get key of current view
    let viewKey = this.getViewKeyFromName(viewNameKey);
    // check if current view is first enabled view
    if (viewKey !== null) {
      return viewKey == this.getFirstEnabledKey();
    }
    return false;
  },

  getNavItems(currentViewName) {
    let lastViewKey = null;
    let currentViewKey = this.getViewKeyFromName(currentViewName);
    // get last complete
    let lastCompleteKey = this.getViewKeyFromName(this.getLastComplete());
    // if last complete is before current, get current view
    lastViewKey =
      !lastCompleteKey || lastCompleteKey < currentViewKey
        ? currentViewKey
        : lastCompleteKey;
    // get all enabled items before key
    let items = [];
    for (let i = 0; i <= lastViewKey; i++) {
      const view = this.sequence[i];
      const isActiveChild =
        currentViewKey &&
        ((this.sequence[currentViewKey].parent &&
          this.sequence[currentViewKey].parent == view.parent) ||
          (this.sequence[currentViewKey].parent &&
            this.sequence[currentViewKey].parent == view.name) ||
          (view.parent && this.sequence[currentViewKey].name == view.parent))
          ? true
          : false;
      if (
        i == currentViewKey ||
        isActiveChild ||
        (!this.isDisabled(i) && !view.navHide)
      ) {
        items.push({
          ...view,
          activeChild: i !== currentViewKey && isActiveChild ? true : false,
          beforeCurrent: i < currentViewKey,
          afterCurrent: i > currentViewKey,
          isCurrent: i == currentViewKey,
        });
      }
    }
    return items;
  },

  /**
   * reusable guard method (per-route guard)
   * checks prerequisite items in sequence (getPreReqTodo())
   * if any of those is not complete, the method will return a redirect object to redirect to the first incomplete view
   * @param {Object} to view object we are navigating to
   * @returns {Object}
   */
  guard(to) {
    // check if prerequisite views are complete
    let viewKey = this.getViewKeyFromName(to.name);
    if (viewKey) {
      let prereqTodo = this.getPreReqTodo(viewKey);
      if (prereqTodo !== null) {
        console.log(
          'redirecting to incomplete prerequisite view: ',
          this.sequence[prereqTodo].name
        ); // #REMOVE BEFORE FLIGHT
        return {
          name: this.sequence[prereqTodo].name,
          params: to.params,
          query: StripQuery(to.query, ['from'])
        };
      }
    }
  },
};

/**
 * return disabled() function
 * @param {string} value api-name of measure to check
 * @returns {boolean}
 */
export function measureDisabledFunction(value) {
  return function () {
    let lead = useLeadStore();
    return lead.interestsArray.includes(value) ? false : true;
  };
}
