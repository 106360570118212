import { computed } from "vue";
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';
import { QualIssue } from '@/services/QualClasses';
// import { parseInt } from "lodash";

function useStores() {
  let lead = useLeadStore();
  let v$ = useGlobalVuelidate();
  return {
    lead: lead,
    v$: v$
  }
}

export const rules = {

  // check if property owner
  PropertyOwner: computed(() => {
    let { lead } = useStores();
    if (lead.ownership.PropertyOwner == false) {
      return new QualIssue({
        title: 'U bent niet de eigenaar',
        view: 'formOwnership',
        description:
          'Alleen de eigenaar van het pand kan toestemming geven voor isolatie.',
      });
    }
    return null;
  }),

  // check if hoa permission is provided
  // HoaPermission: computed(() => {
  //   let { lead } = useStores();
  //   if (lead.ownership.HoaMember == true && !lead.ownership.HoaPermission) {
  //     return new QualIssue({
  //       title: 'Toestemming VVE ontbreekt',
  //       view: 'formOwnership',
  //       description:
  //         'Bij een VVE is toestemming van de vereniging noodzakelijk.',
  //     });
  //   }
  //   return null;
  // }),

  // gas consumption (combined with construction year)
  ConsentForContact: computed(() => {
    let { lead } = useStores();
    if (lead.advice.ConsentForContact == false) {
      return new QualIssue({
        type: 'warning',
        color: 'error',
        title: 'U geeft geen toestemming voor advies',
        description:
          'U heeft aangegeven dat u geen energieavies wil ontvangen. Dit betekent dat wij u niet kunnen helpen met het verduurzamen van uw woning!',
        punishEarly: true,
        view: 'formAdvice'
      });
    }
    return null;
  }),
  HelpWithQuotes: computed(() => {
    let { lead } = useStores();
    if (lead.advice.HelpWithQuotes == false) {
      return new QualIssue({
        type: 'warning',
        color: 'warning',
        title: 'Weet u het zeker?',
        description:
          'Onze hulp is vrijblijvend. U mag altijd van gedachten veranderen. Vanuit onze taak helpen wij u graag gedurende het hele proces met onze kennis en ervaring.',
        punishEarly: true,
        view: 'formAdvice'
      });
    }
    return null;
  }),
  HelpWithVHF: computed(() => {
    let { lead } = useStores();
    if (lead.advice.HelpWithVHF == false) {
      return new QualIssue({
        type: 'warning',
        color: 'warning',
        title: 'Weet u het zeker?',
        description:
          'Onze hulp is vrijblijvend. U mag altijd van gedachten veranderen. Vanuit onze taak helpen wij u graag gedurende het hele proces met onze kennis en ervaring.',
        punishEarly: true,
        view: 'formAdvice'
      });
    }
    return null;
  }),

  // gas consumption (combined with construction year)
  // GasConsumption: computed(() => {
  //   let { lead } = useStores();
  //   let intConstructionYear = parseInt(lead.property.ConstructionYear);
  //   let intGasConsumption = parseInt(lead.property.GasConsumption);
  //   // if less than 800 m3 and younger than 1978, disqualify
  //   if (intConstructionYear > 1978 && intGasConsumption <= 800) {
  //     return new QualIssue({
  //       title: 'Isolatie waarschijnlijk onrendabel',
  //       view: 'formProperty',
  //       description:
  //         'Bij nieuwere huizen (na 1978) met een dergelijk laag gasverbruik is isolatie zeer waarschijnlijk onrendabel.',
  //     });
  //     // if between 800~1700 m3 and younger than 1978, show warning
  //   } else if (
  //     intConstructionYear > 1978 &&
  //     intGasConsumption > 800 &&
  //     intGasConsumption < 1700
  //   ) {
  //     return new QualIssue({
  //       type: 'warning',
  //       title: 'Laag rendement',
  //       description:
  //         'Bij nieuwere huizen (na 1978) met een dergelijk laag gasverbruik heeft isolatie vaak een laag rendement.',
  //     });
  //   }
  //   return null;
  // }),

  // // check if house type is suitable
  // HouseType: computed(() => {
  //   let { lead } = useStores();
  //   if (lead.property.HouseType == 'Garage') {
  //     return new QualIssue({
  //       title: 'Wij isoleren geen garages',
  //       view: 'formProperty',
  //     });
  //   } else if (lead.property.HouseType == 'Anders') {
  //     return new QualIssue({
  //       title: "Uw woningtype is 'anders'",
  //       view: 'formProperty',
  //       description:
  //         "U heeft 'anders' aangegeven als type woning. Indien uw type niet voorkomt in de lijst vragen we u graag contact op te nemen om de mogelijkheden te bespreken met een adviseur.",
  //     });
  //   }
  //   return null;
  // }),

};
