import { useInterfaceStore } from '@/store/UiStore';
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';
import { useQualificationStore } from '@/store/QualificationStore';
import { useAddressStore } from '@/store/AddressStore';
import { useIncomeStore } from '@/store/IncomeStore';

export const useAllStores = () => {
  const lead = useLeadStore()
  const v$ = useGlobalVuelidate()
  const uiStore = useInterfaceStore()
  const qualStore = useQualificationStore()
  const addressStore = useAddressStore()
  const incomeStore = useIncomeStore()
  return { lead, v$, uiStore, qualStore, addressStore, incomeStore };
}