import { defineStore } from 'pinia';
import { useLeadStore } from '@/store/LeadStore';
import { useGlobalVuelidate } from '@/store/LeadValidator';
import { isString, startsWith } from 'lodash';
import router from '@/router';
import { CouponDataService } from '@/services/CouponDataService';

/**
 * InterfaceStore
 * responsible for interface decisions related to progress and context
 * for example, differences in the interface due to whether or not it is an existing lead fetched from Salesforce
 * for example, determining if a parner coupon (relatiecode) was used
 */
export const useInterfaceStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'uiStore',
  state: () => ({
    uuid: null,
    sessionUuid: null,
    // if an attempted to load sf data based on sfuuid, this property should be true
    sfQueried: false,
    // if Salesforce Id was present on load and record was fetched, this property should be true
    fetchedFromSf: false,
    // fetched from session
    fetchedFromSession: false,
    // if valid partner coupon was present in params on load, screen will be disabled
    validPartnerCouponOnLoad: false,
    // answer from partner coupon question (remember for revisit)
    showPartnerCouponForm: false,
    // internal UserId (Salesforce User ID) present
    isInternalUser: false,
    // saving busy state
    saving: false,
    savingError: false,
    // local storage
    incomeRestoreAttempted: false,
    // last loaded route object
    currentRoute: null,
    disableCouponForm: false,
    // address fetch busy state
    addressFetchBusy: false
  }),
  getters: {
    uuidType() {
      return (startsWith(this.uuid, 'sess-')) ? 'session' : 'sf';
    },
    getsessionUuid() {
      return this.sessionUuid ? this.sessionUuid : this.uuid;
    },
    hasValidPartnerCoupon() {
      const v$ = useGlobalVuelidate();
      return v$.value.meta.PartnerCoupon.$invalid == false;
    },
    // used to determine wether to greet user as new or existing customer
    isExistingCustomer(state) {
      return state.fetchedFromSf;
    },
    // skip partner coupon form
    skipFormPartnerCoupon(state) {
      return state.isExistingCustomer || state.validPartnerCouponOnLoad || this.isPortalUser || state.disableCouponForm;
    },
    // check if non-measure specific forms are complete
    generalFormsComplete() {
      let v$ = useGlobalVuelidate();
      if (
        v$.value.interests.$invalid ||
        v$.value.contact.$invalid ||
        v$.value.address.$invalid ||
        v$.value.ownership.$invalid ||
        v$.value.advice.$invalid ||
        v$.value.property.$invalid
      ) {
        return false;
      }
      return true;
    },
    isPortalUser() {
      const lead = useLeadStore();
      return lead.meta.ReturnUrl || lead.meta.CallbackUrl ? true : false;
    }
  },
  actions: {
    setPartnerCouponOnLoad(value = null) {
      if (isString(value) && value.length > 2) {
        let lead = useLeadStore();
        lead.meta.PartnerCoupon = value;
        CouponDataService.verifyCoupon(value)
          .then(() => {
            this.$state.validPartnerCouponOnLoad = true;
          })
          .catch(() => {
            console.log('Partner coupon not valid.');
          })
      }
    },
    setInternalUserIdOnLoad(value = null) {
      if (isString(value) && value.length > 14) {
        let lead = useLeadStore();
        lead.meta.InternalUserId = value;
        this.$state.isInternalUser = true;
      }
    },
    setUuid(value = null, fetched = false) {
      this.uuid = value;
      if (fetched && this.fetchedFromSf == false && this.uuidType == 'session') {
        this.fetchedFromSession = true;
        if (!this.sessionUuid) {
          this.sessionUuid = value;
        }
      }
      this.considerRouteUpdate();
    },
    considerRouteUpdate() {
      if (this.currentRoute && this.uuid) {
        if (this.currentRoute.params.uuid !== this.uuid) {
          let newRoute = {
            name: null,
            params: {},
            query: {}
          }
          newRoute = {...newRoute, ...this.currentRoute};
          newRoute.params = { ...newRoute.params, ...{uuid: this.uuid} }
          router.replace(newRoute);
        }
      }
    }
  },
});
