<template>
  <interview-layout :show-nav="false">
    <div class="container-narrow d-flex flex-column align-items-center">
      <div class="mt-n5 position-relative">
          <h1 class="text-center fs-1-3em fade-in-up-enter-active">Uw gegevens worden opgehaald...</h1>
        <transition name="fade-in-up">
          <h2 v-show="showTakingLong" class="text-center fs-1em">Dit duurt langer dan normaal.</h2>
        </transition>
        <transition name="fade-in-up">
          <h2 v-show="showRefreshSuggestion" class="text-center fs-1em text-warning">Probeer de pagina te herladen.</h2>
        </transition>
      </div>
    </div>
  </interview-layout>
</template>

<script>
  import { LeadDataService } from "@/services/LeadDataService";
  import { CommonComposable } from "@/helpers/CommonComposable";
  import { FlowService } from "@/services/FlowService";
  import InterviewLayout from "@/views/layouts/InterviewLayout.vue"

  export default {
    components: {
      InterviewLayout
    },
    setup(props, context) {
      return CommonComposable(props, context);
    },
    data() {
      return {
        showTakingLong: false,
        showRefreshSuggestion: false
      }
    },
    mounted() {
      this.showTakingLong = false;
      this.showRefreshSuggestion = false;
      // start timeout
      setTimeout(() => {
        this.showTakingLong = true;
      }, 3000);
      setTimeout(() => {
        this.showRefreshSuggestion = true;
      }, 10000);
      // try to fetch data
      (async () => {
        await LeadDataService.fetchRemoteData(this.$route.params.uuid, this.$route.query.force_sync || this.$route.query.force_sync_once);
        // if redir query param is set, redirect back to view
        if (this.$route.query.redir) {
          this.$router.replace({
            name: this.$route.query.redir,
            params: this.$route.params,
            query: this.StripQuery(this.$route.query, ['test'])
          });
        // else redirect to first incomplete section
        } else {
          this.$router.replace({
            name: FlowService.getFirstIncomplete(true),
            params: this.$route.params,
            query: this.StripQuery(this.$route.query, ['test'])
          });
        }
      })().catch(() => {
        this.$router.replace({
          path: '/',
          params: {
            uuid: null
          },
          query: this.StripQuery(this.$route.query)
        });
      });
    },
    beforeRouteLeave() {
      this.showTakingLong = false;
      this.showRefreshSuggestion = false;
    }
  }
</script>