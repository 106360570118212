<template>
  <div :class="getFieldWrapperClass">
    <!-- field label -->
    <label :for="getId" :class="{'form-label': true, 'dim-when-value': this.dimLabelOnValue}">
      <slot name="default"></slot>
    </label>
    <!-- field wrapper -->
    <div :class="getFieldClass" :style="`max-width: ${getFieldWidth}`">
      <!-- validation icon -->
      <div class="validation-icon" v-if="enableValidationIcon">
        <TransitionGroup name="scale-in">
          <i key="icon-valid" class="bi bi-check-circle-fill icon-valid" v-show="showValidationIcon && getValidationState == 'valid'"></i>
          <i key="icon-invalid" class="bi bi-x-circle-fill icon-invalid" v-show="showValidationIcon && getValidationState == 'invalid'"></i>
        </TransitionGroup>
      </div>
      <!-- before input -->
      <div class="before-input" v-if="$slots.before" @click="focusOnInput">
        <div class="before-input-inner">
          <slot name="before"></slot>
        </div>
      </div>
      <!-- input types -->
      <input
        v-if="typeInput"
        ref="input"
        :id="getId"
        :name="name"
        :type="type"
        :class="getInputClass"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :min="min"
        @input="emitUpdate($event.target.value)"
        @focus="hasFocus(true)"
        @blur="onBlur($event.target.value)"
      />
      <textarea
        v-if="type == 'textarea'"
        ref="textarea"
        :id="getId"
        :name="name"
        :class="getInputClass"
        :value="value"
        :placeholder="placeholder"
        :rows="rows"
        @input="emitUpdate($event.target.value)"
        @focus="hasFocus(true)"
        @blur="onBlur($event.target.value)"></textarea>
      <radio-button-input
        v-if="type == 'radiobuttons'"
        ref="radiobuttons"
        :id="name"
        :options="options"
        :value="value"
        :class="getInputClass"
        :direction="direction"
        :variant="variant"
        :disabled="disabled"
        :textAlign="textAlign"
        @update:value="emitUpdate($event)"
      />
      <button-grid-input
        v-if="type == 'buttongrid'"
        ref="buttongrid"
        :name="name"
        :options="options"
        :type="buttonGridInputType"
        :value="value"
        :class="getInputClass"
        :direction="direction"
        :variant="variant"
        :inactiveVariant="inactiveVariant"
        :btnDirection="btnDirection"
        :showInput="showInput"
        :minColWidth="minColWidth"
        :maxCols="maxCols"
        :disabled="disabled"
        @update:value="emitUpdate($event)"
      />
      <select
        v-if="type == 'select'"
        ref="select"
        :name="name"
        :id="getId"
        :value="value"
        :class="getInputClass"
        @input="emitUpdate($event.target.value)"
        @focus="hasFocus(true)"
        @blur="onBlur($event.target.value)"
        >
        <option class="placeholder-option" value="" selected>{{ placeholder }}</option>
        <template v-for="option in this.options" :key="option">
          <option :value="option.value">{{ option.label }}</option>
        </template>
      </select>
      <!-- after input -->
      <div class="after-input" v-if="$slots.after" @click="focusOnInput">
        <div class="after-input-inner">
          <slot name="after"></slot>
        </div>
      </div>
    <!-- field wrapper end -->
    </div>
    <!-- validation message -->
    <ul class="validation-message" v-show="showValidationMessage">
      <li v-for="line in getValidationMessageLines" :key="line">
        {{line}}
      </li>
    </ul>
  </div>
</template>

<script>
  import { debounce } from "lodash"
  import ClassList from "@/helpers/ClassList"
  
  import RadioButtonInput from "./RadioButtonInput.vue"
  import ButtonGridInput from "./ButtonGridInput.vue"

  export default {
    components: {
      RadioButtonInput,
      ButtonGridInput
    },
    setup(props, context) {
      // function to emit update
      const emitFieldValue = function(value) {
        context.emit('update:value', value);
      };
      // function to touch value in Vuelidate
      const touchField = function() {
        if (props.validator) { props.validator.$touch() }
      };

      // debounced emitUpdate method, debounce timeout is set from debounce prop
      const touchDebounced = debounce(function() {
        touchField();
      }, props.debounce);

      // debounce both emit and touch
      const emitDebounced = debounce(function(value, touch = true) {
        emitFieldValue(value);
        if (touch) {
          touchField();
        }
      }, props.debounce - 1);

      // emit immediately, touch debounced
      const emitImmediate = function(value, touch = true, touchNow = false) {
        emitFieldValue(value);
        if (touch) {
          touchNow ? touchField() : touchDebounced();
        }
      };

      /**
       * Emit update
       * 
       * @param {*} value         field value
       * @param Boolean touch     when true, the $touch() will be called on validator
       * @param Boolean emitNow   when false, touch will respect the debounce param
       * @param Boolean touchNow  when emit is not debounced, touch will still be debounced unless touchNow is true
       */
      const emitUpdate = function(value, touch = true, emitNow = false, touchNow = false) {
        this.localValue = value;
        this.localDirty = true;
        if (emitNow || props.debounceTouchOnly) {
          emitImmediate(value, touch, touchNow);
        } else {
          emitDebounced(value, touch);
        }
      };

      return {emitUpdate, touchDebounced}
    },
    props: {

// important props
      // will also become id when id prop is empty
      name: {
        required: true,
        type: String
      },
      // id of the input or textarea element, will default to name if empty
      id: {
        required: false,
        type: String
      },
      // type attribute on input element
      // when type = 'textarea', a textarea element will be rendered
      // when type = 'radiobuttons', an instance of RadioButtonInput will be rendered
      type: {
        default: 'text',
        type: String
      },
      min: {
        type: [Number, String],
        default: null
      },
      buttonGridInputType: {
        type: String,
        default: 'checkbox'
      },
      // options are required when type is radiobuttons
      options: {
        type: [Object, Array]
      },
      // input value, supports v-bind:value
      value: null,
      // placeholder
      placeholder: null,
      // disable input
      disabled: {
        type: Boolean,
        default: false
      },
      // when true, focusOnInput() will be called on mounted()
      focusOnMounted: {
        type: Boolean,
        default: false
      },

// validation props
      // vuelidate object to touch
      validator: null,
      // debounce value update and validator touch
      debounce: {
        type: Number,
        default: 0
      },
      // when true, value update will be emitted immediately on change, only $touch() will respect the debounce param
      debounceTouchOnly: {
        type: Boolean,
        default: false
      },
      // enable validation icon left of input
      enableValidationIcon: {
        type: Boolean,
        default: true
      },
      // validation state provides visual feedback for validation
      forceValidationState: {
        type: String,
        validator(value) {
          return [null, 'neutral', 'valid', 'invalid', 'warning'].includes(value)
        }
      },
      forceValidationMessage: {
        type: String
      },
      // disables valid validation state
      doNotReward: {
        type: Boolean,
        default: false
      },

// styling props
      // input max-width
      fieldWidth: {
        type: [String, Number],
        required: false,
        default: '100%'
      },
      // variant for radio-button-input
      variant: {
        type: String
      },
      // rows attribute on textarea element (only relevant when type = 'textarea')
      rows: {
        type: [Number, String],
        default: 3
      },
      // flex-direction for radio buttons
      direction: {
        type: String,
        default: "row"
      },
      // additional classes on input, textarea or radio-button-input element
      inputClass: {
        type: [String, Object, Array]
      },
      enableBorder: {
        type: Boolean,
        default: (props) => (props.type !== 'radiobuttons')
      },
      enableShadow: {
        type: Boolean,
        default: (props) => (props.type !== 'radiobuttons')
      },
      // when true, the label's opacity is reduced when field has value
      dimLabelOnValue: {
        type: Boolean,
        default: (props) => (props.type !== 'radiobuttons' && props.type !== 'buttongrid')
      },
      // background for entire field group
      fieldBackground: {
        type: String,
        default: 'gray-200'
      },
      inputBackground: {
        type: String,
        default: 'white'
      },

// button grid props
      inactiveVariant: {
        type: String,
        default: (props) => {
          return 'minimal-'+props.variant;
        }
      },
      btnDirection: {
        type: String,
        default: 'row',
        validator(value) {
          return ['column', 'row'].includes(value);
        }
      },
      showInput: { type: Boolean, default: true }, // show/hide input (checkbox)
      minColWidth: { type: Number, default: 150 },
      maxCols: { type: Number, default: null }, // maximum number of columns allowed in the grid
      textAlign: { type: String, default: 'center' }, // text alignment for the label
    },
    data() {
      return {
        focus: false,
        localValue: null,
        localDirty: false
      }
    },
    computed: {
      typeInput() {
        let specialTypes = ['textarea', 'radiobuttons', 'buttongrid', 'select'];
        return !specialTypes.includes(this.type);
      },
      getId() {
        return this.id ? this.id : this.name
      },
      getValidationState() {
        if (this.forceValidationState) {
          return this.forceValidationState
        } else {
          if (this.validator) {
            if (this.validator.$error) {
              return 'invalid'
            } else if (!this.validator.$error && !this.validator.$invalid && !this.focus && this.validator.$dirty && !this.doNotReward) {
              return 'valid'
            }
          }
        }
        return null
      },
      showValidationMessage() {
        // only show message when state is invalid or warning
        if (!this.getValidationMessageLines) { return false }
        if (Array.isArray(this.getValidationState) && this.getValidationState.length > 0) {
          return true
        }
        if (this.getValidationState.length > 0) { return true }
        return false
      },
      getValidationMessageLines() {
        if (this.forceValidationMessage) { return Array.isArray(this.forceValidationMessage) ? this.forceValidationMessage : [this.forceValidationMessage] }
        // if no errors, immediately return
        if (!this.validator || !this.validator.$errors || !Array.isArray(this.validator.$errors) || this.validator.$errors.length < 1) { return }
        // else get all messages in $errors array
        let messages = [];
        this.validator.$errors.forEach(error => {
          if ('$message' in error && error.$message) {
            messages.push(error.$message)
          }
        });
        return messages
      },
      showValidationIcon() {
        if (this.enableValidationIcon) {
          return (this.getValidationState == 'valid' || this.getValidationState == 'invalid') && !this.focus
        }
        return false
      },
      getFieldWrapperClass() {
        return {
          'labeled-field': true,
          'field-focus': this.focus,
          'field-has-value': this.hasValue,
          'field-active': this.isActive,
          'field-valid': this.getValidationState == 'valid',
          'field-invalid': this.getValidationState == 'invalid',
          'field-warning': this.getValidationState == 'warning',
          ['field-type-'+this.type]: true
        }
      },
      getFieldClass() {
        let cl = ClassList({
          'input-field': true,
          'input-border': this.enableBorder,
          'input-shadow': this.enableShadow
        });
        if (this.type !== 'radiobuttons') {
          cl.addClass('bg-' + this.fieldBackground);
        }
        return cl.getAll();
      },
      getFieldWidth() {
        return (typeof this.fieldWidth === 'string' || this.fieldWidth instanceof String)
          ? this.fieldWidth
          : `${this.fieldWidth}px`
      },
      getInputClass() {
        let cl = ClassList(this.inputClass);
        if (this.type !== 'radiobuttons') {
          cl.addClass('bg-' + this.inputBackground);
        }
        if (this.type == 'select' && !this.value) {
          cl.addClass('select-placeholder-selected');
        }
        return cl.getAll();
      },
      hasValue() {
        return (this.value !== null && this.value !== '')
      },
      // field is active if input has value or has focus
      isActive() {
        return (this.hasValue || this.focus)
      },
    },
    methods: {
      hasFocus(value) {
        this.focus = value;
      },
      // sets focus to false
      // emits update immediately if value is defined and we might have to await debounce
      // (otherwise the field might appear empty until debounced callback is run)
      onBlur(value) {
        this.hasFocus(false);
        if (typeof value !== 'undefined') {
          // do not touch (second param = false) if value is empty/null
          this.emitUpdate(value, (value !== '' && value !== null), true, true);
        }
      },
      focusOnInput() {
        if (this.type == 'textarea') {
          this.$refs.textarea.focus()
        } else if (this.type == 'select') {
          this.$refs.select.focus();
        } else if (this.typeInput) {
          this.$refs.input.focus()
        }
      }
    },
    mounted() {
      if (this.focusOnMounted) {
        this.focusOnInput()
      }
    },
    beforeUnmount() {
      if (this.localValue !== null || this.localDirty) {
        this.emitUpdate(this.localValue, false, true);
      }
    }
  }
</script>

<style scoped lang="scss">
  .labeled-field {
    display: flex;
    flex-direction: column;
    margin-top: map-get($spacers, 3);
    margin-bottom: map-get($spacers, 3);
    position: relative;

    label {
      opacity: 1;
      font-size: 0.9em;
      font-weight: 500;
      margin-bottom: 4px;
      padding-left: $input-border-radius / 2;
      cursor: text;
      transition: opacity 0.5s ease;
      will-change: opacity;
    }
    &.field-type-radiobuttons, &.field-type-buttongrid {
      margin-bottom: map-get($spacers, 4);
      label {
        font-size: 0.9em;
        margin-bottom: 10px;
      }
    }

    .input-field {
      display: flex;
      flex-direction: row;
      border-radius: $input-border-radius;
      position: relative;

      &.input-border {
        border: 1px solid $gray-300;
      }
      &.input-shadow {
        @include std-box-shadow();
      }

      input, textarea, select {
        width: 100%;
        border: none;
        padding: map-get($spacers, 2) map-get($spacers, 3);
        border-radius: $border-radius;
        &:focus-visible {
          outline: none;
        }
                
        &::placeholder {
          opacity: 0.5;
        }
      }

      select {
        option {
          font-weight: 500;
          font-size: 1em;
          font-style: normal;
          color: $body-color;
        }

        &.select-placeholder-selected, option.placeholder-option {
          font-style: italic;
          font-size: 0.9em;
          color: $secondary;
        }
      }

      .before-input, .after-input {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .validation-message {
      font-size: 0.9em;
      padding: 0 0;
      margin: 0 0;
      list-style: 0 0;
      li {
        color: inherit;
        display: inline-block;
        padding: 0 map-get($spacers, 2);
        margin-left: map-get($spacers, 2);
        background-color: rgba($secondary, 0.5);
        border-radius:$border-radius;
        &:first-child {
          border-radius: 0 0 $border-radius $border-radius;
        }
        color: $white;
      }
    }
    &.field-type-radiobuttons .validation-message {
      padding-left: 9px;
      li {
        border-top-right-radius: $border-radius;
      }
    }

    .validation-icon {
      position: absolute;
      left: -31px;
      top: calc(50% - 11px);

      i {
        position: absolute;
        display: inline-block;
        animation-duration: 0.15s;
      }

      @mixin validation-icon($color) {
        color: $color;
        &::before {
          @include std-text-shadow($color: $color, $intensity: 8);
        }
      }

      .icon-valid {
        @include validation-icon($success);
      }
      .icon-invalid {
        @include validation-icon($danger);
      }
      .icon-warning {
        @include validation-icon($warning);
      }
    }

    // field states
    &.field-focus {
      label {
        opacity: 1;
      }
      .input-field {
        outline: 3px solid rgba($primary, 0.2);
      }
    }
    &.field-has-value {
      label.dim-when-value {
        opacity: .6;
      }
    }

    // validation states
    &.field-valid {
      .input-field {
        border-color: $success;
        outline-color: rgba($success, 0.2);
      }
      .validation-message li {
        background-color: rgba($success, 0.6);
      }
    }
    &.field-invalid {
      .input-field {
        border-color: $danger;
        outline-color: rgba($danger, 0.2);
        input, textarea, select {
          color: $danger;
        }
      }
      .validation-message li {
        background-color: rgba($danger, 0.5);
      }
    }
    &.field-warning {
      .input-field {
        border-color: $warning;
        outline-color: rgba($warning, 0.2);
      }
      .validation-message li {
        background-color: rgba($warning, 0.6);
      }
    }
  }
</style>