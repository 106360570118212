import { defineStore } from 'pinia';
import { isFunction } from '@vue/shared';
import { useInterfaceStore } from './UiStore';

export const useIncomeStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'IncomeStore',
  state: () => ({
    nettIncome: null
    // grossIncome: null
    // incomePeriod: null
  }),
  getters: {
    // utility getters
    $fields(state) {
      return Object.keys(state)
        .filter(function (key) {
          return (
            key.charAt(0) !== '$' &&
            key.charAt(0) !== '_' &&
            !isFunction(state[key])
          );
        })
        .reduce(function (fields, key) {
          fields[key] = state[key];
          return fields;
        }, {});
    },

    // field getters
    annualIncome() {
      return this.nettIncome * 12;
    },

    localStoreKey() {
      const uiStore = useInterfaceStore();
      return 'bvv_inc_' + uiStore.sessionUuid;
    }
  },
  actions: {
    // save to local storage
    save_local() {
      localStorage.setItem(this.localStoreKey, JSON.stringify(this.$state));
      // console.log('Saved income store to local storage with id: ' + this.localStoreKey);
    },
    // load from local storage
    restore_local() {
      const uiStore = useInterfaceStore();
      // console.log('Restoring local storage id: ' + this.localStoreKey);
      const data = localStorage.getItem(this.localStoreKey);
      // console.log('Restored data: ' + data);
      if (data) {
        const fields = JSON.parse(data);
        for (const key in fields) {
          this[key] = fields[key];
        }
      }
      uiStore.incomeRestoreAttempted = true;
    },
    roundIncome() {
      const incomeString = this.nettIncome ? this.nettIncome.toString() : '';
      // if local income contains . or , parse as float and round to int
      if (incomeString && (incomeString.includes('.') || incomeString.includes(','))) {
        this.nettIncome = Math.floor(parseFloat(incomeString));
      }
    }
  }
});